import { clientConfig } from "@kiosk/audit/config"
import GreensterLogo from "@kiosk/audit/assets/img/greenster.png"
import KioskLogo from "@kiosk/audit/assets/img/kiosk_full.svg"
import { Center } from "@mantine/core"

type LogoProps = {
  height: number
}

export const Logo = ({ height }: LogoProps) => {
  const source =
    clientConfig.company === "GREENSTER" ? GreensterLogo : KioskLogo

  return (
    <Center>
      <img height={height} src={source} alt="company logo" />
    </Center>
  )
}
